import React from 'react';
import MainLayout from '../../layouts/main';
import { Typography } from '@material-ui/core';
import { SEO } from '../../components/SEO';
import { SignupButtonWithChoice } from '../../components/SignupButton';
import PageHead from '../../components/PageHead';
import {
  FeatureGrid,
  LinkGeneratorSpeed,
  LinkGeneratorCustomize,
  LinkGeneratorDelegation
} from '../../components/FeatureGrid';
import { Label } from '../../components/Section';
import { VIDEOS, VimeoVideo } from '../../components/VimeoVideo';

export default () => {
  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/product/affiliate-link-generator/"
          title="Affiliate Link Generator"
          description="Create and manage affiliate links from one place."
        />
        <PageHead>
          <Typography variant="body1" style={{ textAlign: 'center' }}>
            <Label>Also available via API</Label>
          </Typography>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '600px',
              margin: '0 auto 36px',
              fontWeight: 900
            }}
          >
            Scale your link creation with the Universal Affiliate Link Generator
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ maxWidth: '800px', margin: '12px auto 24px' }}
          >
            Create and manage affiliate links from one place. Simply paste in a
            deeplink, and let Affilimate use your affiliate network integrations
            to generate links optimized for tracking and attribution.
          </Typography>
          <div style={{ maxWidth: '800px', margin: '0 auto 36px' }}>
            <VimeoVideo videoId={VIDEOS.LINK_GENERATOR} />
          </div>
          <div>
            <SignupButtonWithChoice text="Scale your affiliate link generation" />
          </div>
        </PageHead>
        <div style={{ maxWidth: '900px', margin: '96px auto 64px' }}>
          <Typography
            variant="h5"
            gutterBottom
            component="h2"
            style={{
              textAlign: 'center',
              fontWeight: 700
            }}
          >
            Create your affiliate links in one place.
          </Typography>
          <Typography
            variant="body1"
            component="p"
            style={{
              maxWidth: '510px',
              margin: '0 auto',
              textAlign: 'center'
            }}
          >
            No more dashboards, bookmarklets, and extensions cluttering your
            browser toolbar.
          </Typography>
        </div>
        <FeatureGrid>
          <LinkGeneratorSpeed />
          <LinkGeneratorDelegation />
          <LinkGeneratorCustomize />
        </FeatureGrid>
      </div>
    </MainLayout>
  );
};
